import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Button, Heading, ListItem, Text, UnorderedList, Box, Link } from "@chakra-ui/react";
import { SEOComponent } from "../components/SEO";
import NextLink from 'next/link'

export default function NotFound() {
  return (
    <div>
      <SEOComponent title="Not Found"/>
      <Heading>Not found</Heading>
      <Text mt={1}>Sorry, the page you are trying to find was not found.</Text>
      <Text mt={2} mb={1}>One of these is the reason why the page was not found:</Text>

      <UnorderedList ml={7}>
        <ListItem>The page does not exist anymore</ListItem>
        <ListItem>The page is not available yet</ListItem>
        <ListItem>You typed a wrong URL.</ListItem>
      </UnorderedList>

      <Box mt={3}>
        <NextLink href="/" passHref>
          <Link _hover={{ textDecoration: 'none' }}>
            <Button colorScheme="gray" href="/" mr={2}>Back to home</Button>
          </Link>
        </NextLink>

        <Link href="https://discord.gg/vWWkVpMjj3" isExternal _hover={{ textDecoration: 'none' }}>
          <Button colorScheme="blue">
            Contact Support <ExternalLinkIcon ml={1}/>
          </Button>
        </Link>
      </Box>
    </div>
  )
}